import React from 'react';
import './DriveNavbar.css';
import { NavLink } from 'react-router-dom';

const DriveNavbar = () => {
  return (
    <nav className="drive-nav">
      <div className="drive-wrapper">
        <NavLink to="/home" className="logo-link">
          <img
            src="./images/logo_02.png"
            className="logo-img"
            alt="logo"
          />
        </NavLink>
        <ul className="drive-nav-links">
          <li className="nav-item">
            <NavLink
              to="/DriveMain"
              className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'}
            >
              Main Drive
            </NavLink>
          </li>
          <li className="nav-item mega-menu-parent">
          <NavLink
             to="/DriveThermalFootageInflu"
             className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'}
          >
             Thermal Footage Influencers
          </NavLink>
          <div className="mega-menu">
            <ul className="mega-menu-item">
              <li><a href="https://drive.google.com/drive/folders/1V3gjwNL47zpfL6U8wOIeydiEJGv-qnY_?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">NZ Influencers Footages</a></li>
              <li><a href="https://drive.google.com/drive/folders/1-fScARk0L1Lv4u_wei1eAK6IUs6RpIyV?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">M15 Trail Camera</a></li>
              <li><a href="https://drive.google.com/drive/folders/1-iNh60sjfvCK6EIFl15jaehAO68U0GPR?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">M15 trail cam videos</a></li>
              <li><a href="https://drive.google.com/drive/folders/1ieveJlpTcHL0F__1XrKj5ydrsjoV4Esr?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">How To Upload Video To Drive</a></li>
            </ul>
            <ul className="mega-menu-item">
              <li><a href="https://drive.google.com/drive/folders/1iHlrQ3VuxCp84BU15haT1nF_aHW2lg5M?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">Habrok Pro</a></li>
              <li><a href="https://drive.google.com/drive/folders/13xYD8XuL8wO3sm6GuDZea8B9kQPmcNWf?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">Condor</a></li>
              <li><a href="https://drive.google.com/drive/folders/1CQXwYHU0mG66NbZ9aQICYTj1RDbEyY8Z?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">AU Influencers Footages</a></li>
              <li><a href="https://drive.google.com/drive/folders/1-2WsKlhUm_wBKmWfxtEkW6Rl1_Gu91XP?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">Panther 2.0 Ballistic Calculator</a></li>
            </ul>
            <ul className="mega-menu-item">
              <li><a href="https://drive.google.com/drive/folders/1-8bSyLQMlFN-ARwvHdBTg2YyMG2S3f9S?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">Alpex 4k</a></li>
              <li><a href="https://drive.google.com/drive/folders/1rdA6opyo7SNhffkeokzXTBv47ekuud3x?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">Christi Viljoen</a></li>
              <li><a href="https://drive.google.com/drive/folders/1Wl68DKFguLWksHRtHcQFfQKR0-tWS6U1?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">Falcon FQ50</a></li>
              <li><a href="https://drive.google.com/drive/folders/1--2D_T2a-udopuCvU_HfrJ-08RVoe8xe?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">Stella 3.0</a></li>
            </ul>
            <ul className="mega-menu-item">
              <li><a href="https://drive.google.com/drive/folders/1H6gyf7RbS6fyjgc-J-NPJba3GL7Z3xfl?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">Steve Riley</a></li>
              <li><a href="https://drive.google.com/drive/folders/1LJP_d1MMVketvyiMmlkJkhD3Dss7cuPr?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">Thunder 2.0 TQ35</a></li>
              <li><a href="https://drive.google.com/drive/folders/1VtInWL64ySgTlaOiG71zv0U1dCZLflyb?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">Thunder Zoom</a></li>
               <li><a href="https://drive.google.com/drive/folders/1i2EVwM8WDwKvdzuaoMgNwHPxEOtXSJ7i?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">YouTube Guides and Clips</a></li>
            </ul>
          </div>
          </li>

          <li className="nav-item">
            <NavLink
              to="/DriveHoInternal"
              className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'}
            >
              HO Internal
            </NavLink>
            <ul className="drive-drop-menu">
              <li className="drive-drop-item">
                <a
                  href="https://drive.google.com/drive/folders/1-ovlEUCAc4dTYYx5bJwmQoqoaM7jh44r"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="drive-drop-link nav-link"
                >
                  HO Website Tools
                </a>
              </li>
              <li className="drive-drop-item">
                <a
                  href="https://drive.google.com/drive/folders/1il1jgGukwrhckWrXqWswdnAfN4UJoZII"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="drive-drop-link nav-link"
                >
                  HO-User Guides
                </a>
              </li>
              <li className="drive-drop-item">
                <a
                  href="https://drive.google.com/drive/folders/1tNr7FjcDYMB5Xs19jHPBsMWDcf1XMWk2"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="drive-drop-link nav-link"
                >
                  HO-Products Ranges-Inventory Control
                </a>
              </li>
              <li className="drive-drop-item">
                <a
                  href="https://drive.google.com/drive/folders/1AgQEFP2br5buKUy6G8aJhfWE7ImlKhhn"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="drive-drop-link nav-link"
                >
                  HO-HMRMA Repairs-Fault Claims
                </a>
              </li>
              <li className="drive-drop-item ho-marketing-menu">
  <span className="drive-desktop-item nav-link">HO-Marketing For Everything</span>
  <div className="mega-menu ho-marketing-mega-menu">
    <ul className="mega-menu-item">
      <li><a href="https://drive.google.com/drive/folders/1Zgc4ZmZbgWyzvr2ZesQS5g2FaXpcPeM-" target="_blank" rel="noopener noreferrer" className="mega-menu-link">Sub Brands Marketing Folder</a></li>
      <li><a href="https://drive.google.com/drive/folders/1b7zNvTKx7DG50S3SzTpEO8llIqTDfeEv" target="_blank" rel="noopener noreferrer" className="mega-menu-link">HO-Onsite Marketing</a></li>
      <li><a href="https://drive.google.com/drive/folders/1jSN_rQl_WScCN-UGEJck249AykvBsbaO" target="_blank" rel="noopener noreferrer" className="mega-menu-link">HO-Online Marketing</a></li>
      <li><a href="https://drive.google.com/drive/folders/1HlXHyZTzhUjCvOoP-_JOHkgV641Cz2RB" target="_blank" rel="noopener noreferrer" className="mega-menu-link">HO-Ofline Marketing Inc Assets</a></li>
    </ul>
    <ul className="mega-menu-item">
      <li><a href="https://drive.google.com/drive/folders/1bt80D2SltzkAqIZOQCFn47WnEhrrZAM9" target="_blank" rel="noopener noreferrer" className="mega-menu-link">HO-Influencers</a></li>
      <li><a href="#" target="_blank" rel="noopener noreferrer" className="mega-menu-link">2024 HO AU& NZ Marketing claim invoices</a></li>
      <li><a href="https://docs.google.com/spreadsheets/d/1MGnct_h26UbZ8b0zUPqSLTsFpJ79LNt1/edit?usp=drive_link&ouid=102448624181840008952&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer" className="mega-menu-link">HO Marketing plan.xlsx</a></li>
      <li><a href="https://docs.google.com/spreadsheets/d/1ACHgxXFRfJxk7FmCJ_ThE9D6md7J3ip26V1_S1QPdf8/edit?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">Haris Role actions</a></li>
    </ul>
    <ul className="mega-menu-item">
      <li><a href="https://docs.google.com/spreadsheets/d/1X4Q4hztOAdn_OuqndinbHALRwOKvcP6CIrlpxz6TSwE/edit?usp=drive_link" target="_blank" rel="noopener noreferrer" className="mega-menu-link">Graphic Request Form(Responses)</a></li>
      <li><a href="https://docs.google.com/spreadsheets/d/1ExqV45hitEBkE5ypYYEteX-lX-BQmZ9Y/edit?usp=drive_link&ouid=102448624181840008952&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer" className="mega-menu-link">2024 HO AU NZ-HM CONTRIBUTION CHARGES for Entire 2024</a></li>
      <li><a href="https://docs.google.com/spreadsheets/d/1Y68j56z9ICGb8X9YnjKg7_4eWDq1zuve/edit?usp=drive_link&ouid=102448624181840008952&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer" className="mega-menu-link">2024 HO AU & NZ-HM CONTRIBUTION CHARGES</a></li>
    </ul>
  </div>
</li>


            </ul>
          </li>
          <li className="nav-item">
            <NavLink
              to="/DriveExternalForCustomer"
              className={({ isActive }) => isActive ? 'nav-link active-link' : 'nav-link'}
            >
              HO External Drive for Customers
            </NavLink>
            <ul className="drive-drop-menu">
              <li className="drive-drop-item">
                <span className="drive-desktop-item nav-link">HO Customer Dealer</span>
                <ul className="drive-nested-dropdown">
                  <li className="drive-nested-item nav-link">
                    <a
                      href="https://drive.google.com/drive/folders/1wDCQbY6ZbI1pnG5xk30YTMLeo7HfqSz7?usp=drive_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="drive-nested-link nav-link"
                    >
                      Online Assets
                    </a>
                  </li>
                  <li className="drive-nested-item nav-link">
                    <a
                      href="https://drive.google.com/drive/folders/1KEDAW5Jv3RXaNCtR4B9e6Ki7TUQaPLyM?usp=drive_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="drive-nested-link nav-link"
                    >
                      Offline Assets
                    </a>
                  </li>
                </ul>
              </li>
              <li className="drive-drop-item">
                    <a
                      href="https://docs.google.com/spreadsheets/d/1Bvf248HxoUFFN23DEA1VU2wGBla2IvOuKiramYlnbsU/edit?usp=drive_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="drive-drop-link nav-link"
                    >
                      PriceList New Zealand
                    </a>
                  </li>
              <li className="drive-drop-item">
                    <a
                      href="https://docs.google.com/spreadsheets/d/1DMCdgtofDFZxcD10uJu-PG5_hwq58fADITBtqAbr2SE/edit?usp=drive_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="drive-drop-link nav-link"
                    >
                      Price List Australia
                    </a>
              </li>
              <li className="drive-drop-item">
                <a
                  href="https://drive.google.com/drive/folders/1_sguKQ36eUJgzOpWlH9EZsf1zD20cmAd?usp=drive_link"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="drive-drop-link nav-link"
                >
                  Social Media Sharing
                </a>
              </li>
              <li className="drive-drop-item">
                <a
                  href="https://drive.google.com/drive/folders/1TfWif24UFFUUN-xfA0Rv6nEmAeKfsZtC?usp=drive_link"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="drive-drop-link nav-link"
                >
                  YouTube Videos for Retailers
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default DriveNavbar;
