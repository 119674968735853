import React from 'react';
import ReactFlow, { Controls, Background, Handle } from 'reactflow';
import 'reactflow/dist/style.css';
import { folderStructure, edges } from '../Data/DriveFolderStructure';

const CustomNode = ({ data }) => {
  return (
    <div
      style={{
        border: 'none',
        borderRadius: '50%',
        width: '180px',
        height: '180px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '20px',
        color: '#FFF',
        background: data.isMain ? '#213555' : '#3E5879',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
        textAlign: 'center',
        position: 'relative',
      }}
    >
      {data.label}
      <Handle
        type="source"
        position=""
        style={{ opacity: 0 }}
      />
      <Handle
        type="target"
        position=""
        style={{ opacity: 0 }}
      />
    </div>
  );
};

const nodeTypes = {
  custom: CustomNode,
};

const DriveHoInternalMindMap = () => {
  // Map nodes to use custom styling
  const nodes = folderStructure.map((node) => ({
    ...node,
    type: 'custom',
    data: {
      label: node.data.label,
      isMain: node.id === 'main',
    },
  }));

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes} 
        nodesConnectable={false} 
        edgesConnectable={false}
        elementsSelectable={false}
        nodesDraggable={false}
        fitView
        defaultEdgeOptions={{
          type: 'straight',
          style: { stroke: '#543A14', strokeWidth: 3 },
        }}
      >
        <Controls />
        <Background color="#F0F0F0" gap={16} />
      </ReactFlow>
    </div>
  );
};

export default DriveHoInternalMindMap;

