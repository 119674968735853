// Utility function for circular layout
const getCircularPosition = (centerX, centerY, radius, index, total) => {
    const angle = (2 * Math.PI * index) / total;
    return { x: centerX + radius * Math.cos(angle), y: centerY + radius * Math.sin(angle) };
  };

const center = { x: 400, y: 400 };
const radius = 220;
const childRadius = 180; // Smaller radius for child nodes

// Main Node
export const externalFolderStructure = [
    { id: 'main', data: { label: 'HO-External Drive For Customers' }, position: center },

    // Sub-Nodes (Placed in a circle)
    { id: 'sub-1', data: { label: 'HO-Customer Dealer' }, position: getCircularPosition(center.x, center.y, radius, 0, 5) },
    { id: 'sub-2', data: { label: 'Pricelist New Zealand' }, position: getCircularPosition(center.x, center.y, radius, 1, 5) },
    { id: 'sub-3', data: { label: 'Pricelist Australia' }, position: getCircularPosition(center.x, center.y, radius, 2, 5) },
    { id: 'sub-4', data: { label: 'Social Media Sharing' }, position: getCircularPosition(center.x, center.y, radius, 3, 5) },
    { id: 'sub-5', data: { label: 'Youtube Videos For Retailers' }, position: getCircularPosition(center.x, center.y, radius, 4, 5) },

    // Child Nodes for HO-Customer Dealer
    { id: 'sub-1-1', data: { label: 'Online Assets', isChildNode: true },  position: { x: getCircularPosition(center.x, center.y, radius, 0, 5).x + childRadius, y: getCircularPosition(center.x, center.y, radius, 0, 5).y - childRadius } },
    { id: 'sub-1-2', data: { label: 'Offline Assets', isChildNode: true },  position: { x: getCircularPosition(center.x, center.y, radius, 0, 5).x + childRadius, y: getCircularPosition(center.x, center.y, radius, 0, 5).y + childRadius } },
];

export const externalEdges = [
    { id: 'e-main-1', source: 'main', target: 'sub-1', type: 'straight', style: { stroke: '#555b6c', strokeWidth: 10 } },
    { id: 'e-main-2', source: 'main', target: 'sub-2', type: 'straight', style: { stroke: '#555b6c', strokeWidth: 10 } },
    { id: 'e-main-3', source: 'main', target: 'sub-3', type: 'straight', style: { stroke: '#555b6c', strokeWidth: 10} },
    { id: 'e-main-4', source: 'main', target: 'sub-4', type: 'straight', style: { stroke: '#555b6c', strokeWidth: 10} },
    { id: 'e-main-5', source: 'main', target: 'sub-5', type: 'straight', style: { stroke: '#555b6c', strokeWidth: 10} },

    // Edges for HO-Customer Dealer Child Nodes
    { id: 'e-sub-1-1', source: 'sub-1', target: 'sub-1-1', type: 'straight', style: { stroke: '#555b6c', strokeWidth: 5 } },
    { id: 'e-sub-1-2', source: 'sub-1', target: 'sub-1-2', type: 'straight', style: { stroke: '#555b6c', strokeWidth: 5 } },
];