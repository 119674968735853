// DriveHoExternalForCustomer.jsx
import React, {useCallback}  from'react';
import ReactFlow, { Controls, Background, Handle } from 'reactflow';
import 'reactflow/dist/style.css';
import { externalFolderStructure, externalEdges } from '../Data/DriveExternalForCustomerFolderStructure';

const ExternalDriveNode = ({ data }) => {
    const isChildNode = data.isChildNode;
  return (
    <div
      style={{
        border: 'none',
        borderRadius: '50%',
        width: isChildNode ? '140px' : data.isMain ? '180px':'160px', 
        height: isChildNode ? '140px':data.isMain ? '180px':'160px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#FFF',
        background: isChildNode ? '#f46036' : data.isMain ? '#13293d' : '#a4161a',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
        textAlign: 'center',
        position: 'relative',
        padding:"10px",
        overflow:"hidden",
      }}
    >
      {data.label}
      <Handle
        type="source"
        position=""
        style={{ opacity: 0 }}
      />
      <Handle
        type="target"
        position=""
        style={{ opacity: 0 }}
      />
    </div>
  );
};

const nodeTypes = {
  custom: ExternalDriveNode,
};

const DriveHoExternalForCustomer = () => {
  const onInit = useCallback((reactFlowInstance) => {
      reactFlowInstance.setViewport({ x:120, y:-100, zoom: 0.98 }); 
    }, []);
  // Map nodes to use custom styling
  const nodes = externalFolderStructure.map((node) => ({
    ...node,
    type: 'custom',
    data: {
      label: node.data.label,
      isMain: node.id === 'main',
      isChildNode:node.data.isChildNode,
    },
  }));

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={externalEdges}
        nodeTypes={nodeTypes}
        nodesConnectable={false}
        edgesConnectable={false}
        elementsSelectable={false}
        nodesDraggable={false}
        fitView
        onInit={onInit}
        defaultEdgeOptions={{
          type: 'straight',
          style: { stroke: '#4E9F3D', strokeWidth: 3 },
        }}
      >
        <Controls />
        <Background color="#F0F0F0" gap={16} />
      </ReactFlow>
    </div>
  );
};

export default DriveHoExternalForCustomer;
