// Define positions for left and right nodes
const center = { x: 500, y: 350 }; // Central node position
const leftX = 250; // X position for left-side nodes
const rightX = 750; // X position for right-side nodes
const yStart = 200; // Starting Y position for sub-nodes
const ySpacing = 75; // Vertical spacing between sub-nodes

// Define the nodes with specific names
export const thermalNodes = [
  // Central node
  { id: 'main', data: { label: 'Thermal Footage Influencers', isMain: true }, position: center, type: 'thermal' },

  // Left-side nodes with specific names
  { id: 'left-1', data: { label: 'NZ influencers Footages', isLeft: true }, position: { x: leftX, y: yStart }, type: 'thermal' },
  { id: 'left-2', data: { label: 'M15 trail camera ', isLeft: true }, position: { x: leftX, y: yStart + ySpacing }, type: 'thermal' },
  { id: 'left-3', data: { label: 'M15 trail cam videos', isLeft: true }, position: { x: leftX, y: yStart + 2 * ySpacing }, type: 'thermal' },
  { id: 'left-4', data: { label: 'How To Upload Video To Drive', isLeft: true }, position: { x: leftX, y: yStart + 3 * ySpacing }, type: 'thermal' },
  { id: 'left-5', data: { label: 'Habrok Pro', isLeft: true }, position: { x: leftX, y: yStart + 4 * ySpacing }, type: 'thermal' },
  { id: 'left-6', data: { label: 'Condor', isLeft: true }, position: { x: leftX, y: yStart + 5 * ySpacing }, type: 'thermal' },
  { id: 'left-7', data: { label: 'AU Influencers Footages', isLeft: true }, position: { x: leftX, y: yStart + 6 * ySpacing }, type: 'thermal' },
  { id: 'left-8', data: { label: 'Panther 2.0 Ballistic Calculator', isLeft: true }, position: { x: leftX, y: yStart + 7 * ySpacing }, type: 'thermal' },

  // Right-side nodes with specific names
  { id: 'right-1', data: { label: 'Youtube guides and Clips', isRight: true }, position: { x: rightX, y: yStart }, type: 'thermal' },
  { id: 'right-2', data: { label: 'THUNDER ZOOM', isRight: true }, position: { x: rightX, y: yStart + ySpacing }, type: 'thermal' },
  { id: 'right-3', data: { label: 'Thunder 2.0 tq35', isRight: true }, position: { x: rightX, y: yStart + 2 * ySpacing }, type: 'thermal' },
  { id: 'right-4', data: { label: 'Stella 3.0', isRight: true }, position: { x: rightX, y: yStart + 3 * ySpacing }, type: 'thermal' },
  { id: 'right-5', data: { label: 'Falcon FQ50', isRight: true }, position: { x: rightX, y: yStart + 4 * ySpacing }, type: 'thermal' },
  { id: 'right-6', data: { label: 'Christi Viljoen', isRight: true }, position: { x: rightX, y: yStart + 5 * ySpacing }, type: 'thermal' },
  { id: 'right-7', data: { label: 'Alpex 4k', isRight: true }, position: { x: rightX, y: yStart + 6 * ySpacing }, type: 'thermal' },
  { id: 'right-8', data: { label: 'steve Riley', isRight: true }, position: { x: rightX, y: yStart + 7 * ySpacing }, type: 'thermal' },
];

// Define edges connecting the main node to each sub-node
export const thermalEdges = [
  // Left-side edges
  { id: 'e-main-left-1', source: 'main', target: 'left-1', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-left-2', source: 'main', target: 'left-2', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-left-3', source: 'main', target: 'left-3', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-left-4', source: 'main', target: 'left-4', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-left-5', source: 'main', target: 'left-5', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-left-6', source: 'main', target: 'left-6', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-left-7', source: 'main', target: 'left-7', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-left-8', source: 'main', target: 'left-8', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },

  // Right-side edges
  { id: 'e-main-right-1', source: 'main', target: 'right-1', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-right-2', source: 'main', target: 'right-2', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-right-3', source: 'main', target: 'right-3', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-right-4', source: 'main', target: 'right-4', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-right-5', source: 'main', target: 'right-5', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-right-6', source: 'main', target: 'right-6', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-right-7', source: 'main', target: 'right-7', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
  { id: 'e-main-right-8', source: 'main', target: 'right-8', type: 'straight', style: { stroke: '#543A14', strokeWidth: 3 } },
];
