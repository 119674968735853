import React from 'react';
import DriveHoExternalMindMap from './DriveHoExternalMindMap';

const DriveHoExternalForCustomers = () => {

  return (
    <div>
        <DriveHoExternalMindMap/>
    </div>
  );
};

export default DriveHoExternalForCustomers;
