import React, {useCallback} from 'react';
import ReactFlow, { Controls, Background, Handle } from 'reactflow';
import 'reactflow/dist/style.css';
import { thermalNodes, thermalEdges } from '../Data/DriveThermalStructure';
// import { useCallback } from 'react';

const ThermalNode = ({ data }) => {

  return (
    <div
      style={{
        border: data.isMain ? '3px solid #ffffff' : '1px solid #a4161a',
        borderRadius: data.isMain ? '50%' : '50px',
        width: data.isMain ? '180px' : '180px',
        height: data.isMain ? '180px' : '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '18px',
        color: data.isMain ? '#ffffff' : '#ffffff',
        background: data.isMain ? '#213555' : '#a4161a',
        textAlign: 'center',
        boxShadow: data.isMain ? '0 4px 8px rgba(0, 0, 0, 0.3)' : 'none',
        padding: data.isMain ? "10px" : "15px",
        overflow:"hidden",
        position:"relative",
      }}
    >
      {data.label}
      <Handle
        type="source"
        position=""
        style={{ opacity: 0 }}
      />
      <Handle
         type="target"
         position=""
         style={{ opacity: 0 }}
      />
    </div>
  );
};

const nodeTypes = {
  thermal: ThermalNode,
};

const DriveThermalFootage = () => {
  const onInit = useCallback((reactFlowInstance) => {
    reactFlowInstance.setViewport({ x: 130, y:-100, zoom: 0.98 }); 
  }, []);

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <ReactFlow
        nodes={thermalNodes}
        edges={thermalEdges}
        nodeTypes={nodeTypes}
        nodesConnectable={false}
        edgesConnectable={false}
        elementsSelectable={false}
        fitView
        onInit={onInit}
        defaultEdgeOptions={{
          type: 'straight',
          style: { stroke: '#543A14', strokeWidth: 5 },
        }}
      >
        <Controls />
        <Background color="#f3f4f6" gap={18} />
      </ReactFlow>
    </div>
  );
};

export default DriveThermalFootage;
