import React from "react";
import "./DriveMain.css";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const DriveMain = () => {
  return (
    <div>
    <div className="drive-main-container">
      {/* Card 1 */}
      <div className="drive-main-card">
        <div className="drive-main-card-content">
          <Link to='/DriveThermalFootageInflu'>
          <img src="/images/mindmaplogo.png" alt="Card 2" className="drive-main-card-image" />
          <p className="drive-main-card-title">Thermal Footage influencers Map</p>
          </Link>
        </div>
      </div>

      {/* Card 2 */}
      <div className="drive-main-card">
        <div className="drive-main-card-content">
        <Link to='/DriveHoInternal'>
          <img src="/images/mindmaplogo.png" alt="Card 1" className="drive-main-card-image" />
          <p className="drive-main-card-title">HO Internal Map</p>
        </Link>

        </div>
      </div>

      {/* Card 3 */}
      <div className="drive-main-card">
        <div className="drive-main-card-content">
          <Link to='/DriveExternalForCustomer'>
          <img src="/images/mindmaplogo.png" alt="Card 3" className="drive-main-card-image" />
          <p className="drive-main-card-title">HO External drive for customers Map</p>
          </Link>
        </div>
      </div>
    </div>
    <Footer/>
</div>
  );
};

export default DriveMain;
