import React from 'react';
import DriveHoInternalMindMap from './DriveHoInternalMindMap';

const DriveHoInternal = () => {

  return (
    <div> 
        <DriveHoInternalMindMap/>
    </div>
  );
};

export default DriveHoInternal;
