// Utility function for circular layout
const getCircularPosition = (centerX, centerY, radius, index, total) => {
  const angle = (2 * Math.PI * index) / total;
  return { x: centerX + radius * Math.cos(angle), y: centerY + radius * Math.sin(angle) };
};

const center = { x: 400, y: 400 };
const radius = 250; 
// Main Node
export const folderStructure = [
  { id: 'main', data: { label: 'HuntsmanOptics Internal Drive' }, position: center },

  // Sub-Nodes (Placed in a circle)
  { id: 'sub-1', data: { label: 'HO Website Tools' }, position: getCircularPosition(center.x, center.y, radius, 0, 6) },
  { id: 'sub-2', data: { label: 'HO-User Guides' }, position: getCircularPosition(center.x, center.y, radius, 1, 6) },
  { id: 'sub-3', data: { label: 'HO-Products Ranges-Inventory Control' }, position: getCircularPosition(center.x, center.y, radius, 2, 6) },
  { id: 'sub-4', data: { label: 'HO-Marketing Folder For Everything' }, position: getCircularPosition(center.x, center.y, radius, 3, 6) },
  { id: 'sub-5', data: { label: 'HO-HM RMA Repairs-Fault Claims' }, position: getCircularPosition(center.x, center.y, radius, 4, 6) },
  { id: 'sub-6', data: { label: 'HO-Customer Folder-Database-Directory' }, position: getCircularPosition(center.x, center.y, radius, 5, 6) },
];

export const edges = [
  { id: 'e-main-1', source: 'main', target: 'sub-1', type: 'straight', style: { stroke: '#543A14', strokeWidth: 5 } },
  { id: 'e-main-2', source: 'main', target: 'sub-2', type: 'straight', style: { stroke: '#543A14', strokeWidth: 5 } },
  { id: 'e-main-3', source: 'main', target: 'sub-3', type: 'straight', style: { stroke: '#543A14', strokeWidth: 5 } },
  { id: 'e-main-4', source: 'main', target: 'sub-4', type: 'straight', style: { stroke: '#543A14', strokeWidth: 5 } },
  { id: 'e-main-5', source: 'main', target: 'sub-5', type: 'straight', style: { stroke: '#543A14', strokeWidth: 5 } },
  { id: 'e-main-6', source: 'main', target: 'sub-6', type: 'straight', style: { stroke: '#543A14', strokeWidth: 5 } },
];
